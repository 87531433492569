import { ErrorBoundary, Show, Suspense, createResource } from "solid-js";
import CTAs from "~/components/Cards/Components/CTAs";
import CardGrid from "~/components/Cards/Components/CardGrid";
import PreviewText from "~/components/Cards/Components/PreviewText";
import { getWrapper } from "~/lib/fetcher";
import VisualContainer from "~/components/Cards/Components/VisualContainer";
import CardShell from "~/components/Cards/CardShell";
import { isOutOfStock, isPreview } from "~/utils/helper_program";

import "./Card.css";
import type { ItemReference, SearchParams } from "~/types/drupal_jsonapi";
import { useMapContext } from "~/contexts/MapContext";
import { brandClassName } from "~/utils/format";
import Typologies from "~/components/Cards/Components/Typologies";

export default function Card(props: {
  nid: number;
  initialDeferStream?: boolean;
  item: ItemReference;
  searchParams?: SearchParams;
}) {
  const [wrapper] = createResource(() => props.nid, getWrapper, {
    deferStream: props.initialDeferStream,
  });

  const [, { setMarkerNid }] = useMapContext();

  return (
    <>
      <ErrorBoundary
        fallback={(err, reset) => (
          <p style="border: 1px solid red" onClick={reset}>
            Error: {err.toString()}
          </p>
        )}
      >
        <Suspense
          fallback={<CardShell title={props.item.title} url={props.item.url} />}
        >
          <Show when={wrapper()}>
            <article
              class={`node-program-card search-result ${brandClassName(wrapper()!.program.field_brand.field_id)}`}
              data-test={`card-program-${wrapper()!.program.field_program_id}`}
              data-ga-zone="card"
              onMouseEnter={() => {
                setMarkerNid!(props.nid);
              }}
            >
              <VisualContainer wrapper={wrapper()!} />

              <Typologies
                wrapper={wrapper()!}
                searchParams={props.searchParams}
              />

              <div class="content-program">
                <Show
                  when={!isPreview(wrapper()!)}
                  fallback={
                    <>
                      <PreviewText />
                    </>
                  }
                >
                  <Show when={!isOutOfStock(wrapper()!)}>
                    <CardGrid
                      wrapper={wrapper()!}
                      searchParams={props.searchParams}
                    />
                  </Show>
                </Show>
              </div>

              <CTAs wrapper={wrapper()!} />
            </article>
          </Show>
        </Suspense>
      </ErrorBoundary>
    </>
  );
}
