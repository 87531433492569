import type { ProgramWrapper } from "~/utils/program_wrapper";
import { createMemo, createSignal, For, Show } from "solid-js";
import { getGrid, isOutOfStock } from "~/utils/helper_program";

import IconExpandLess from "~/img/icons/expand_less.svg";
import GridTypologyRow from "~/components/Program/Components/GridTypologyRow";
import type { SearchParams } from "~/types/drupal_jsonapi";
import Accordion from "@corvu/accordion";

export default function CardGrid(props: {
  wrapper: ProgramWrapper;
  searchParams?: SearchParams;
}) {
  const grid = createMemo(() => getGrid(props.wrapper, props.searchParams));

  const typologiesCount = createMemo(() => {
    return grid().length;
  });

  const [isCollapsed, setIsCollapsed] = createSignal(true);

  return (
    <>
      <Show when={!isOutOfStock(props.wrapper)}>
        <div
          class="card-mask"
          classList={{ disabled: typologiesCount() <= 2 || !isCollapsed() }}
        >
          <div class="grid">
            <Accordion collapseBehavior="hide">
              <For each={grid()}>
                {(gridTypology) => (
                  <GridTypologyRow
                    gridTypology={gridTypology}
                    wrapper={props.wrapper}
                    displayingForCard={true}
                  />
                )}
              </For>
            </Accordion>
          </div>
          <Show when={typologiesCount() >= 3}>
            <div
              class="gradient"
              onClick={() => setIsCollapsed(!isCollapsed())}
            />
          </Show>
        </div>
        <Show when={typologiesCount() >= 3 && !isCollapsed()}>
          <button
            type="button"
            class="view-less"
            onClick={() => {
              setIsCollapsed(!isCollapsed());
            }}
          >
            Afficher moins
            <i aria-hidden="true" class="cog-icon">
              <IconExpandLess />
            </i>
          </button>
        </Show>
      </Show>
    </>
  );
}
